
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');
html{
  font-size: 16px;
  font-family: 'Roboto',sans-serif;

}
.main{
  background-color:#ececec;
  height: 100vh;


}
body{
  font-family: 'Roboto',sans-serif;

}
.containerr{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  overflow-y: hidden;
}
.signup-form{
  width: 500px;
  background-color: white;
  margin: auto 0;
  padding: 30px 50px 30px 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(136, 133, 133, 0.3) 0px 3px 7px -3px;

  
}
p,h1,h2,h3,h4,h5,h6,label{
  /* color: #420c69; */
  color: black;
  opacity: 85%;
}
.form-box{
  display: flex ;
  gap: 1rem;
}.patient-box{
  background-color:#dfe1e2;
 /* padding-top: 30px;
 padding-bottom: 30px; */
 height: 100vh;

}
.patient-form{

  background: white;
  padding: 50px 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(136, 133, 133, 0.3) 0px 3px 7px -3px;
  width: 40%;

}
.patient-form h1{
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;


}
.quiz-btns{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-col{
  display: flex ;
  flex-direction: column !important;
}
.w-100{
  width: -webkit-fill-available;
}
.patient-form .form-group{
  margin-bottom: 10px;
}
.patient-form .react-datepicker__input-container input{
  width: -webkit-fill-available !important;
}
.width-100{
  width: 100%;
}
.radio-label{
  padding: 10px ;
  border: 1px solid gray;
  width: -webkit-fill-available;
}
.patient-form .form-group{
  width:-webkit-fill-available;
}
.form-box input{
  width: auto !important;
}
.radios-box{
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.signup-form label{
  font-size: 0.9rem;
font-weight: bold;
}
.form-group label{
  font-size: 0.8rem;
  font-weight: 700;
  color: #000000;
}
.form-group input{
  
  width: 100%;
  border: 1px solid #EAEAEA;
  padding: 10px 14px;
  font-size: 0.75rem;
  outline: none;
}
.form-group{
  display: flex;
  flex-direction: column;
 

}
.main-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 200px; */
  text-align: center;
  height: 100vh;
}
.main-box h1{
  color: #0692B6;
  font-weight: bold;
  margin-bottom: 50px;
}
.next-btn{
  border: none;
  width: 400px;
  background-color: #0692B6;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 20px;
  padding: 15px;
  text-transform: capitalize;
}
.delete-btn{
  border: none;
  width: fit-content;
  background-color: #0692B6;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 20px;
  padding: 15px;
  text-transform: capitalize;
}
.home-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
/* margin-top: 200px; */
height: 100vh;
position: relative;
text-align: center;
}
.home-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

min-height: 100vh;
position: relative;
text-align: center;
background-color:#ececec;
}
.home-div h4{
  color: black;
}
.home-box h4{
  color: black;
}
.hospital-name{
  top: 150px;
  position: absolute;
}
.signup-form h1{
  font-size: 1.5rem;
  font-weight: 800;
  text-align: center;
  margin: 20px 0 40px 0;
}
.logout-div{
  display: flex;
  justify-content: end;
}
.logout-btn{
  padding: 10px;
  outline: none;
  border: none;
  font-weight: 600;
  color: white;
  background-color: #0692B6;

}
.logo-div2{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 20%;
  margin-bottom: 10px;
}
.logo-div2 img{
  width: 100%;
  height: 100%;
}
.logo-div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
  height: 4%;
  margin-bottom: 10px;
}
.logo-div img{
  width: 100%;
  height: 100%;
}
.staff-logout-div{
width: 100%;
display: flex;
  
}
.staff-logout-div button{
  padding: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: #0692B6;
  margin:30px 50px 10px auto
}
.submit-btn button{
  border: none;
  background-color: #0692B6;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 90px;
  margin-top: 20px;

}
.toggle-password i:hover{
  cursor: pointer;
  opacity: 0.8;

}
.submit-btn{
  display: flex;
  justify-content: center;

}
.logo{

  text-align: center;
}
.login-link{
  font-size: 0.75rem;
  font-weight: 500;

}
.login-link a:hover{
  color: rgb(38, 38, 104);
  cursor: pointer;
}
button:hover{
  cursor: pointer;
  opacity: 0.7;

}
.login-anchor{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.password-input{
  position: relative;
}
.toggle-password{
  position: absolute;
  right: 20px;
  top: 10px;
}
.form-group i{
  color: #000000;
  margin: 0 10px;
}
.form-group label{
  margin: 7px 0px 5px 0px;
}


.form-group input::placeholder {
  color: gray; 
  opacity: 75%;
  font-style: italic;
}
/*                QUESTIONS           */
.questions h4{
  font-size: 22px;
  font-weight: bold;
}
.questions h5{
  font-size: 18px;
}
.questions p{
  font-size: 1rem ;
  font-weight: bold;
  
}
.questions h6{
  font-size: 14px;
  font-style: italic;
  padding: 0;
  margin: 0;
}
.questions label{
  margin: 10px ;
}
.next-quest-btn{
  border: none;
  background-color: #0692B6;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 20px;
  width: 200px;
  padding: 10px;
  /* width: 50%;
  margin-left: auto;
  margin-right: auto; */
  text-transform: capitalize;
}
.common-btn{
  border: none;
  background-color: #0692B6;
  color: white;
  font-weight: 600;
  font-size: 14px;
  /* margin-top: 20px; */
  width: fit-content;
  padding: 10px;
  /* width: 50%;
  margin-left: auto;
  margin-right: auto; */
  text-transform: capitalize;
}
.options input{
  margin-right: 7px;
}
.question-heading{
  
    border: none;
    background-color: #0692B6;
    color: white !important;
    font-weight: 600;
    width: fit-content;
    padding: 10px;
    text-transform: capitalize;
  
}
.assessment-container{
  display: flex;
  justify-content: center;
  align-items: left;
  color: #000000;
  /* height: 100vh; */
  width: 90%;
  padding: 20px 0;
  position: relative;
}
.ReactModal__Content{
  position: absolute !important;
  top: 30vh !important;
  left: 35vw !important;
  inset: 0 !important;
  width: max-content;
  height: max-content;

  
}
.ReactModal__Overlay{
  top: 30vh !important;
  left: 35vw !important;
  background-color: none !important;
  width: max-content !important;
}
.modal-btn1{
  width: fit-content;
  border: none;
  outline: none;
  width: 70px;
  padding:5px 10px;
}
.modal-btn2{
  width: fit-content;
  border: none;
  padding: 5px 10px;
  outline: none;
  width: 70px;
  background-color: #0692B6;
  color: white;
}
.modal-btn1:hover,.modal-btn2:hover{
  cursor: pointer;
  opacity: 70%;
}
.dropdown{
  width: 500px;
  display: flex;
  flex-wrap: wrap;
}
.patient-box{
  position: relative;
}
.time-label{
  position: absolute;
  top: 0;
  display: flex;
}
.timer{
  position: absolute;
  top: 30px;
  left: 50px;
  width: 100px ;
  height: 100px;
  border-radius: 100%;
  background-color: #800080;
  color: white;
  font-weight: bolder;
  font-size: 20px ;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.result-table{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  
  padding: 20px;
  margin-bottom: 50px;
}
.result-logout{
  background-color: rgb(226, 160, 18);
  color: white;
}
.result-table table{
  margin-bottom: 20px;
  margin: 10px;
}
.result-table  table tr{
  display: block;
column-span: 1;

}
.result-table  table tr:first-child{
  border: 1px solid black;
border-bottom: none;
}
.result-table  table tr:nth-child(2){
  border: 1px solid black;
border-bottom: none;
}
.result-table  table tr:nth-child(3){
  border: 1px solid black;
border-bottom: none;
}
.result-table  table tr:nth-child(4){
  border: 1px solid black;
border-bottom: none;
}
.result-table  table tr:nth-child(5){
  border: 1px solid black;
}

.result-table  table td{
 padding: 10px;
 width: 200px;
}
.result-table  table h2{
text-align: center;
 }
  .align-left{
    text-align: left !important
  }
  .align-right{
    text-align: right !important
  }

  .coping-box{
    padding: 13px;
    width: 370px;
    font-weight: bold;
    font-size: 20px;
    color: white;
  }
  .error-message{
    color: red;
    font-size: 11px;
    font-style: italic;
    padding: 2px 0px;
  }

  .cursor-pointer{
    cursor:  pointer !important;
  }

/* .App{
  padding: 0 40px;
  overflow: hidden;
  display:flex;
  justify-content: center;
} */
  .abc{
    max-width: 80%;
    overflow: hidden !important;
    background:lightpink;
  }
  .staff-div {
    font-family: Arial, sans-serif;
    /* max-width: 800px; */
    margin: auto;
    padding-top: 30px;
    /* display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  .pending-table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow: auto;
    height: auto; */
    /* padding-top: 18%;
    margin-top: 30px; */
  }



  .react-datepicker__month-select,.react-datepicker__year-select{
    border: none;
    border-radius: 5px;
    padding: 5px;
    outline: none;
  }

  .react-datepicker__current-month{
    margin-bottom: 20px;
    margin-top: 10px !important;
  }
  .react-datepicker__navigation{
    top: 10px !important;
  }


  .forget-text{
    font-size: 13px;
    margin-top: 5px;
    color:#0692B6 ;
  }
  .forget-text:hover{
    cursor: pointer;
    color: blue;
  }


  
  .staff-div h6{
    padding: 10px 0px;
  }
  
  .staff-div table {
    width: 100%;
    border-collapse: collapse;
   
    padding: 20px ;
  }
  .table-div{
    padding: 20px;
  }
  
  
  
  .staff-div th, .staff-div td {
    padding: 15px 8px;
    text-align: left;
  }
  
  .staff-div th {
    background-color: #e9e6e6;
  }
  

  .pending-table {
    font-family: Arial, sans-serif;
    max-width: 800px;
  }
  .pending-table h6{
    padding: 10px 0px;
  }
  
  .pending-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  
  
  .pending-table th, .pending-table td {
    padding: 15px 8px;
    text-align: left;
  }
  
  .pending-table th {
    background-color: #e9e6e6;
  }
  
  .delete-btn {
    background-color:#0692B6;

    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
  }
  
  .delete-btn:hover {
    background-color:#0692B6;
    opacity: 80%;
  }
  
  .staff-div table th:first-child,
  .staff-div table td:first-child,
  .staff-div table th:last-child,
  .staff-div table td:last-child {
  text-align: center;
}

.pending-table table th:first-child,
.pending-table table td:first-child,
.pending-table table th:last-child,
.pending-table table td:last-child {
text-align: center;
}
/* .table-striped tbody tr:nth-child(odd) {
  background-color: #f9f9f9; 
} */

.absolute-btn{
  position: absolute;
  right: 50px;
  top: 0px;
}
h1,h2,h3,h4,h5,h6{
  color: black !important;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
  @media screen and (max-width:480px) {
    .signup-form{
      width: 100%;
      display: block;
      /* height: 100%; */
      justify-content: center;
      align-items: center;
      padding: 20px 20px 0px 20px ;
      
      overflow-y: hidden;
      box-shadow: none;
      
    }
    .main {
      background-color: white;
      /* height: 100vh; */
  }
  .patient-box{
    background-color: white;
  }
  .patient-form{
    width: 100% !important;
    padding: 50px 30px;
    box-shadow: none;
  }
    .form-group input{
width: -webkit-fill-available;    }
  }
  .btn-disable:disabled{
    background-color: rgb(167, 167, 167);
  }
  .option{
    margin: 10px 0px;
  }

  .select-one p{
    font-style: italic;
    font-size: 14px;
  }

  .go-back-btn{
    background-color: white;
    border: none;
    outline: none;
    font-size: 14px;
  }
  .go-back-btn i{
    margin-right: 3px;
  }

  .superadmin-home h3{
    font-weight: 700;
    color: #000000;

  }
  .super-logo{
    width: 150px;
    height: 150px;
    position: absolute;
    top: 100px;
    left: 15%
  }
  .super-logo img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  @media screen and (max-width:500px) {
    .next-btn{
      width: 70%;
    }
    
    
.form-group input::placeholder {
font-size: 10px;
}
.toggle-password {
  font-size: 13px;
}
  }

  @media screen and (max-width:380px){
    .toggle-password {
      right:0 !important
    }
  }
  @media screen and (max-width:360px){
    .toggle-password {
      right:-5px !important
    }
  }
  @media screen and (max-width:700px) {
  .dropdown{
    width: 80%;
  }
  .patient-form{
    width: 70%;
  }
  }

  @media screen and (max-width:1000px) {
    .logo-div2{
      width: 20%;
    }
    .patient-form{
      width: 50%;
    }}
    @media screen and (max-width:780px) {
      .logo-div2{
        width: 20%;
      }
    
      .table-div{
        padding: 0px;
      }
      .super-logo{
        width: 120px;
        height: 120px;}
      
        .absolute-btn{
           right: 10px;
        
        }
      }

 